/* You can add global styles to this file, and also import other style files */

.alert-error-input {
  margin-top: 1px;
  color: red !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-rigth {
  position: absolute;
  right: 0;
}

.no-results {
  height: 55px;
  padding-top: 15px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background-color: white;
  font-weight: 100;
  color: #827878;
}

.dialog-no-padding {
  padding: 0 !important;
}

.mat-dialog-container {
  padding: 0px !important;
}

/* Scroll bar css  */

/* Let's get this party started */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.5);
}
